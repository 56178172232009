export const getBudget = ({ query, userCurrency }) => {
  return {
    currency: 'currency' in query ? ref(query?.currency) : ref(userCurrency),
    maxBudget: 'maxPrice' in query ? ref(query.maxPrice) : ref('1000000'),
    minBudget: 'minPrice' in query ? ref(query.minPrice) : ref('0'),
  }
}

export const setPriceFilter = ({ minBudget, maxBudget }) => {
  if (Number(minBudget) !== 0 || Number(maxBudget) !== 1000000) {
    return [Number(minBudget) / 7, Number(maxBudget) / 7]
  }

  return [0, Math.round(Number(1000000 / 7))]
}
